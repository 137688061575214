import * as React from "react";

import Seo from "../components/seo";
import PageLayout from "../components/layout/PageLayout";
import { Hero404Content } from "../components/sections";

const NotFoundPage = ({ location }) => {
    const config = {
        header: '404',
        subheader: "Nie odnaleźliśmy takiej strony",
        cta: {
            title: "Wróć na start",
            to: "/",
        },
    };

    return (
        <>
            <Seo
                lang="pl-PL"
                title="404 | P.O.W. Kaliska"
                description="404 - Nie odnaleźliśmy takiej strony"
            />

            <PageLayout location={ location }>
                <Hero404Content config={ config } />
            </PageLayout>
        </>
    );
};

export default NotFoundPage;
